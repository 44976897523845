import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import HomePageCarousel from '../components/carousels/HomePageCarousel';
import AccentLine from '../components/AccentLine';
import SavingsSlider from '../components/slider/SavingsSlider';
import FeaturedListingsCarouselFromFeed from '../components/carousels/FeaturedListingsCarouselFromFeed';
import TestimonialsCarousel from '../components/carousels/TestimonialsCarousel';
import NewsletterModal from '../components/modal/NewsletterModal';
import { VideoMasthead } from '../components/VideoMasthead';

import StyledIndexPage from '../styles/pages/StyledIndexPage';
import theme from '../styles/theme/theme';

import arrowDown from '../img/arrow_down.svg';

export const IndexPageTemplate = ({
	mastheadCarousel,
	video,
	teamBg,
	testimonialsBg,
	toggleModal,
}) => (
	<StyledIndexPage>
		<section className="masthead-section">
			<div className="masthead-content">
				<h1 className="has-text-centered">
					This Spring, Keep More of Your Money for What Matters
				</h1>
				<AccentLine />
				<p className="has-text-centered sub-head">List your home for 1%</p>
				<div className="button-tray">
					<Link to="/sell-your-home" className="button button-1">
						Sell with Us
					</Link>
					<Link to="/buy-a-home" className="button button-3">
						Buy with Us
					</Link>
				</div>
			</div>
			<HomePageCarousel images={mastheadCarousel} />
			<div className="masthead-fade" />
			<img className="arrow-down" src={arrowDown} alt="" />
		</section>
		<section className="section save-section" id="savings">
			<div className="container">
				<div className="columns is-centered">
					<div className="column has-text-centered">
						<h2>Save When You Sell with Us</h2>
						<AccentLine align="center" />
						<p className="sub-head">Slide to select your home’s value</p>
						<SavingsSlider />
						<Link className="button button-1" to="/sell-your-home">
							Sell with Parker Coulter
						</Link>
					</div>
				</div>
			</div>
		</section>
		<section className="experience-section">
			<VideoMasthead
				background={video.thumb}
				mobileBackground={video.mobileThumb}
				video={video.url}
			>
				<div className="container">
					<div className="columns is-centered">
						<div className="column has-text-centered">
							<div className="experience-section-heading-wrapper">
								<h2>Experience Parker Coulter Real Estate Brokerage</h2>
								<AccentLine align="center" color={theme.colors.primary} />
							</div>
						</div>
					</div>
				</div>
			</VideoMasthead>
		</section>

		<section className="section featured-section">
			<div className="container">
				<div className="columns is-centered">
					<div className="column has-text-centered">
						<h2>Featured Listings</h2>
						<AccentLine align="center" />
						<p className="sub-head">Find your new home</p>
					</div>
				</div>
				<FeaturedListingsCarouselFromFeed />
				<div className="has-text-centered">
					<Link to="/listings" className="listings-link">
						See All Listings
					</Link>
				</div>
			</div>
		</section>

		<section
			className="section our-mission-section"
			style={{
				backgroundImage: `url(${teamBg.desktop.childImageSharp.fluid.src})`,
			}}
		>
			<div className="container">
				<div className="columns">
					<div className="column is-4 mission-column">
						<h2>Our Mission</h2>
						<AccentLine />
						<p className="sub-head">The Parker Coulter Team</p>
						<p>
							Our goal is simple: to provide quality service at an affordable
							price. We believe that paying a high commission to sell your home
							is a thing of the past. In this technology-driven world, we've
							brought every efficiency to our business model so we can keep
							overhead costs down. This allows us to pass along significant
							savings to our clients-without compromising on service in any way.
						</p>
						<p>
							As a full-service brokerage, we provide all the tools and the
							exceptional service necessary to sell your home. Ultimately, our
							goal is the same as yours: to make sure you get the best possible
							return on your investment.
						</p>
						<div className="services">
							<h2>Our Promise to You is to Provide</h2>
							<ul>
								<li>Unparalleled Service</li>
								<li>Unmatched Value</li>
								<li>Unsurpassable Results</li>
							</ul>
						</div>
						<Link className="button button-2 with-arrow" to="/sell-your-home">
							Why Choose Us
						</Link>
					</div>
					<div
						className="column team-column"
						style={{
							backgroundImage: `url(${teamBg.mobile.childImageSharp.fluid.src})`,
						}}
					>
						<div className="visually-hidden">
							<h2>Our Team Consists of:</h2>
							<ul>
								<li>
									<h3 className="team-title">Broker of Record</h3>
									<p className="team-name">Parker Coulter</p>
								</li>
								<li>
									<h3 className="team-title">Marketing Manager</h3>
									<p className="team-name">Nicole Stirk</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section
			className="section testimonials-section"
			style={{
				backgroundImage: `url(${testimonialsBg.childImageSharp.fluid.src})`,
			}}
		>
			<div className="container">
				<div className="columns">
					<div className="column has-text-centered">
						<h2>Testimonials</h2>
						<AccentLine align="center" />
						<p className="sub-head">What our clients have to say</p>
					</div>
				</div>
				<div className="columns is-centered">
					<div className="column is-8">
						<TestimonialsCarousel />
					</div>
				</div>
			</div>
		</section>
		{toggleModal && <NewsletterModal />}
	</StyledIndexPage>
);

IndexPageTemplate.propTypes = {
	title: PropTypes.string,
};

const IndexPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { isNewsletterPopup } = data.toggleModal.frontmatter;

	return (
		<Layout page="home">
			<SEO
				title={frontmatter.title}
				description="Parker Coulter Low Commission Realtors®. List Your Home For 1% Commission With A Full Service Real Estate Brokerage Offering Unparalleled Service, Premium Marketing & Commision Savings."
			/>
			<IndexPageTemplate
				mastheadCarousel={frontmatter.home_page_carousel}
				video={frontmatter.home_page_video}
				teamBg={frontmatter.team_bg}
				testimonialsBg={frontmatter.testimonials_bg}
				toggleModal={isNewsletterPopup}
			/>
		</Layout>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default IndexPage;

export const indexPageQuery = graphql`
	query IndexPage($id: String) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				home_page_carousel {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
				home_page_video {
					url
					title
					thumb {
						childImageSharp {
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid
							}
						}
					}
					mobileThumb {
						childImageSharp {
							fluid(maxWidth: 600) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				team_bg {
					desktop {
						childImageSharp {
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid
							}
						}
					}
					mobile {
						childImageSharp {
							fluid(maxWidth: 900) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				testimonials_bg {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
		toggleModal: markdownRemark(frontmatter: { name: { eq: "Settings" } }) {
			frontmatter {
				isNewsletterPopup
			}
		}
	}
`;
